.root-invoice {
  .mt-40 {
    margin-top: 40px;
  }
  
  .mt-30 {
    margin-top: 30px;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .mt-10 {
    margin-top: 10px;
  }
  
  .mb-5 {
    margin-bottom: 5px;
  }
  
  .mr-10 {
    margin-right: 10px;
  }
  
  .ml-30 {
    margin-left: 30px;
  }
  
  .p-4-8 {
    padding: 4px 8px !important;
  }
  
  .p-5 {
    padding: 5px;
  }
  
  .pb-10 {
    padding-bottom: 10px;
  }
  
  .my-item {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }  
}
