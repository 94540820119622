.badge-secure-new {
  border-radius: 10px;
  margin-top: 1rem !important;
  padding: 22px 3rem;
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .card-body {
    padding: 0;

    .pay-icons {
      background-color: #ffffff;
      padding: 0.3rem;
      border-radius: 10px;
      margin: auto !important;
    }

    strong {
      margin-top: -3px;
      font-size: 1.385rem;
      margin-bottom: 1.7rem;
      font-family: 'Open sans', sans-serif;
      font-weight: 700;
      line-height: 1.5;
      color: #393f49;
      text-transform: none;
      letter-spacing: normal;
    }

    small {
      margin-top: -6px;
      font-size: 1.231rem;
      margin-bottom: 1.7rem;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 400;
      line-height: 1.5;
      color: #393f49;
      text-transform: none;
      letter-spacing: normal;
      color: #6c757d !important;
    }
  }
}

.secure-2 {
  text-align: center;

  &-title {
    font-weight: 700;
  }
}

.pay-icons-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: .6rem;

  > .rui-icon {
    width: 2.3rem;
    height: 2.3rem;
    color: #2fc787;
  }
}
