.root-invoice {
  .app {
    margin: 30px auto 50px auto;
    width: 700px;
  }
  
  .input,
  .span,
  .select {
    display: inline-block;
    width: 98%;
    padding: 0px 12px 0px 0;
    margin-bottom: 8px;
    border: 1px dotted transparent;
    transition: background 0.1s ease-in-out, border-color 0.2s ease-in-out;
  }
  
  .input,
  .select {
    &:hover,
    &:focus {
      background: $color-yellow;
    }
  
    &:focus {
      border-color: $color-cyan;
    }
  
    &.white {
      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }
  
  .invoice-wrapper {
    position: relative;
    background: #fff;
    padding: 40px 35px;
    box-shadow: 0 0 17px 0 rgba(16, 40, 73, 0.09);
  }
  
  .download-pdf {
    position: fixed;
    top: 100px;
    margin-left: -110px;
    width: 40px;
    height: 40px;
    background: url('../images/download.svg');
    transition: opacity 0.2s ease-in-out;
  
    &.loading {
      opacity: 0.3;
    }
  
    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  .hover-none:hover {
    background-color: transparent;
  }
  
  .invoice--logo {
    width: 60px;
    height: auto;
    margin-right: .5rem;
  }
}
