.product-video-cover {
  width: 100%;
  // height: 230px;
  overflow: hidden !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media (max-width: 780px) {
    height: 340px;
  }

  @media (max-width: 470px) {
    height: 240px;
  }
}

// Wistia
.wistia_responsive_padding {
  padding: 56.25% 0 0 0;
  position: relative;
}

.wistia_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.wistia_embed {
  height: 100%;
  position: relative;
  width: 100%;
}

.wistia_swatch {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
}

.wistia__image {
  filter: blur(5px);
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.files__download__wrapper h1 {
  font-weight: 700;
  font-size: 1.4rem;

  @media screen and (max-width: 992px) {
    font-size: 1.2rem;
    margin-top: 0.35rem;
  }
}