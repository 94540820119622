@import "../../../assets/css/variables";

.read-more-span {
  color: #2980b9;
  font-weight: bold;
}

.maxh-none {
  max-height: none !important;
}

.minh-none {
  min-height: unset !important;
}

// Settings
$global-font-size: 14px;
$global-line-height: 1.8;
$global-bg-color: white;

@mixin read-more(
  $rows: 4,
  $font-size: $global-font-size,
  $line-height: $global-line-height,
  $bg-color: $global-bg-color,
  $trigger-color: #2980b9,
  $animation: 500ms ease,
  $el: (
    content: ".content",
    trigger: ".trigger",
    expander: ".expanded",
  ),
  $hover: false
) {
  // Optimal height based on your font size and line-height
  $height: (($font-size * $line-height) * $rows);
  @if map-get($el, trigger) != null {
    $height: $height + ($font-size * $line-height); // + 1 for trigger
  }

  @if $hover {
    @if map-get($el, content) {
      #{map-get($el, content)} {
        max-height: $height;
        &::before {
          height: $font-size + ($font-size * 2);
        }
      }
    }
  } @else {
    position: relative;
    text-decoration: none;
    line-height: $line-height;
    cursor: pointer;

    @if map-get($el, trigger) {
      cursor: text;
      #{map-get($el, trigger)} {
        //display: block;
        //position: absolute;
        bottom: 0;
        cursor: pointer;
        color: $trigger-color;
        font-weight: bold;
      }
    }

    @if map-get($el, content) {
      #{map-get($el, content)} {
        position: relative;
        overflow: hidden;
        max-height: $height;
        transition: max-height $animation;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: $font-size + ($font-size * 1.5);
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba($bg-color, 1) 50%,
            rgba($bg-color, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          @if map-get($el, expander) != null {
            transition:
              opactiy $animation,
              visibility $animation;
          }
        }
      }
    }

    @if map-get($el, expander) {
      &#{map-get($el, expander)} {
        #{map-get($el, content)}::before,
        #{map-get($el, trigger)} {
          opacity: 0;
          visibility: hidden;
        }
        #{map-get($el, content)} {
          max-height: 600px; // great value for height animation
        }
      }
    }
  }
}

.read-more {
  @include read-more();
}
