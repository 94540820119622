.alert {
  &-success {
    border-left: 2px solid;
    border-color: #2fc787 !important;
  }
  &-danger {
    border-left: 2px solid;
    border-color: #ef5164 !important;
  }
}
