@import '../../../../assets/css/variables';
@import '../../../../assets/css/rootui-parts/sign';

.responsivearticle {
  margin: 10px 20px;

  @media screen and (min-width: 540px) {
    width: 50%;
    margin: 10px auto;
  }

  @media screen and (min-width: 1000px) {
    width: 35%;
  }
}
