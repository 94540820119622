// .card-img-top-detail{
//   border-radius: 10px 10px 0 0;
// }

.custom-card-new {
  border: none;
  border-radius: 0 0 12px 12px;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
