@import '../../../../assets/css/variables';
@import '../../../../assets/css/rootui-parts/sign';

// .body-wrapper {
//   background-color: #f8f9fa !important;
// }
// .full--height {
//   height: 100vh !important;
// }
.text-link-gray {
  color: #6c757d !important;
}
.text-link-dark {
  color: #6c757d !important;
}
.wrapper__navbar {
  background-color: white;
}
.wrapper__konten {
  .icon__support {
    border-radius: 50%;
    padding: 0.8rem;
    font-size: 2.5rem;
    svg {
      width: 2rem;
      height: 1.5rem;
    }
  }
  .number__support {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #e7f1ff;
    color: #245cd3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
.support_card input,
.support-message,
//  this will impact to global validation too if activate the code below
// .invalid-feedback,
.input__number,
.support-alert {
  font-family: Open Sans, sans-serif !important;
  color: #343a40 !important;
}
.b-12 {
  border-radius: 12px !important;
}
.b-12-top {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}
.icon__socmed {
  border: none;
  border-radius: 50%;
  color: black;
  svg {
    width: 2rem;
    height: 1.5rem;
  }
  &:hover {
    svg {
      width: 2.1rem;
      height: 1.5rem;
    }
  }
}
.input__number {
  width: 40px;
  padding: 0.8rem;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.shadow__bx {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}
.supp-connect-line {
  &::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: calc(100% - 100px);
    background: rgba(34, 34, 34, 0.1);
    position: absolute;
    top: 44px;
    left: 20px;
    z-index: 0;
  }
}
.max__width__400 {
  @media (min-width: 768px) {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.card .card-body .form {
  .list-group-item {
    padding: 0.75rem 1.45rem !important;
  }
  .pl-30 {
    padding-left: 30px !important;
  }
}
.support_container {
  .support_name {
    h1 {
      font-size: 2em;
    }
  }
  .support_profile {
    font-size: 1.2em;
  }
  .supporters {
    h1 {
      font-size: 5em;
      line-height: 1.25em;
    }
  }
  .support_recent {
    font-size: 1.7em;
  }
}

@media only screen and (min-width: 992px) {
  .support_row {
    margin-left: calc(9% - 97.5px);
  }
}
