@media screen and (max-width: 500px) {
  .payment-reminder {
    .list-group-item {
      padding: 1.5rem 1.75rem;
    }
  }
}

.just-tablet {
  display: none;
}

.just-mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .just-tablet {
    display: block;
  }

  .just-mobile {
    display: none;
  }
}
