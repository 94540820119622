// Ewallet
.dana {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 10px 0px;
  box-sizing: border-box;
  padding: 26px !important;
  max-width: 475px !important;
  border-radius: 3.25px;

  &-image {
    display: flex;
    justify-content: center;
    margin-bottom: 17px;
  }

  &-title {
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 13px;
    }
  }
}