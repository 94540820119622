.flippen-size {
  font-size: 1.6em;
}

.label-size {
  font-size: 1em;
}

.tick-flip-panel {
  color: #555;
  background-color: #fafafa;
}

hr.solid {
  border-top: solid 1px #999;
}
.tick-credits {
  display: none !important;
}
