// .btn-broken-size {
//   width: 50%;
// }

.text-size {
  width: 75%;
}

@media screen and (min-width: 768px) {
  // .btn-broken-size {
  //   width: 25%;
  // }

  .text-size {
    width: 50%;
  }
}

.height-logo-karen {
  height: 14rem;
}

.invoice-membership-link {
  color: #3e72eb !important;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .height-logo-karen {
    height: 16rem;
  }
}
