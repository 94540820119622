.radio-prices-tier {
  width: 100% !important;
}

.btn-choose-payment {
  padding: 10px;
}

.checkout-input {
  min-height: 45px;
}

@media screen and (max-width: 500px) {
  .checkout-input {
    min-height: 65px;
  }

  .btn-choose-payment {
    padding: 1.7rem 1.1rem;
    font-size: 0.9rem;
  }

  .checkout-payment-title {
    font-size: 1.3rem;
  }

  .checkout-select-payment {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .select-payment-item {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .checkout-sub-title {
    font-size: 18px;
  }

  .select-shipping-input {
    font-size: 1.25rem;
  }

  .btn-choose-expedition {
    height: 60px;
  }
}

.color-red {
  color: red;
}



@media screen and (max-width: 320px) {
  .flex-xs-column {
    flex-direction: column;
  }
}

.just-tablet {
  display: none;
}

.just-mobile {
  display: block;
}

.border-width-1 {
  border-top-width: thin !important;
}

@media screen and (min-width: 768px) {
  .just-tablet {
    display: block;
  }

  .just-mobile {
    display: none;
  }

  .min-h-60 {
    min-height: 60px;
  }
}

.button-none {
  padding: 0;
  border: none;
  background: none;
}

.table-checkout {
  &.fixed {
    table-layout: fixed;
  }

  tr {
    padding: 0 0.9rem !important;
  }

  .table-hr {
    padding: 0 0.9rem !important;
  }
}
