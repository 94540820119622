
iframe {
  height: 400px;
}

@media only screen and (max-width: 768px) {
  iframe {
    height: 375px;
  }
}

@media only screen and (max-width: 600px) {
  iframe {
    height: 300px;
  }
}


.product-price {
  h4 {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 10px;
  }
}

/* Basic editor styles */
.tiptap {
  font-weight: 400;
  color:#565656;

  :first-child {
    margin-top: 0;
  }

  div[data-youtube-video] {
    cursor: move;

    iframe {
      border: 0.5rem solid black;
      display: block;
      min-height: auto;
      min-width: 100%;
      outline: 0px solid transparent;
    }

    &.ProseMirror-selectednode iframe {
      outline: 3px solid gray;
      transition: outline 0.15s;
      width: 100%;
    }
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 13px;
    margin: 16px 13px 16px 5px;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    line-height: 1.5;
    margin-top: 33px;
    text-wrap: pretty;
  }

  h1, 
  h2 {
    margin-top: 46px;
    margin-bottom: 20px;
  }

  /* Code and preformatted text styles */
  code {
    border-radius: 5px;
    color: black;
    font-size: 11px;
    padding: 0.25em 0.3em;
  }

  pre {
    background: black;
    border-radius: 7px;
    color: white;
    font-family: 'JetBrainsMono', monospace;
    margin: 20px 0;
    padding: 11px 13px;

    code {
      background: none;
      color: inherit;
      font-size: 13px;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid #565656;
    margin: 20px 0;
    padding-left: 13px;

    p {
      margin: 0;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #a1a1a1;
    margin: 26px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
