@media screen and (max-width: 500px) {
  .checkout-discount-btn {
    font-size: 10.4px;
  }

  .use-coupon {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
